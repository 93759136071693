.wrapper{
  padding: 1.2rem 1rem;
  position: relative;

  @include breakpoint(medium){
    padding: 5rem 1.5rem 3rem;
  }

  @include breakpoint(large){
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.wrapper-mini{
  padding: 1.2rem 1rem 0;
  position: relative;

  @include breakpoint(medium){
    padding: 2rem 1.5rem 0;
  }

  @include breakpoint(large){
    padding: 3rem 4rem 0;
  }
}

.wrapper--inner{
  background: $light-gray-dark;
  padding-top: 1rem;
}

//Flexbox sticky footer
html{
  height: 100%;
}

body{
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;

  @include breakpoint(smallmedium down){
    background-color: $dark-gray;
  }
}

.footer-separator{
  flex-grow: 1;
}

//fixes for off-canvas wrappers
.off-canvas-wrapper{
  background-color: $light-gray;
}

.off-canvas-wrapper,
.off-canvas-wrapper-inner,
.off-canvas-content{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.off-canvas{
  background-color: $dark-gray;

  .close-button{
    color: $light-gray;
    padding: .2rem .5rem;
    margin-right: -.5rem;
  }
}

.translation-bar{
  a.button{
    margin-bottom: 0;
    margin-top: -4px;
    margin-right: 5px;
  }
}
