// This is a file that can be overridden by the application in order to override
// some of the Foundation/Decidim SCSS settings and variables after the default
// settings have been loaded.
//
// To override styles use decidim_application.scss
//
// By default this is empty.

// Variables
// 
$primary: rgb(239,0,0);

$body-font-color: rgb(55,72,94);